(function($) {
  $.extend($.summernote.lang, {
    'zh-TW': {
      font: {
        bold: '粗體',
        italic: '斜體',
        underline: '底線',
        clear: '清除格式',
        height: '行高',
        name: '字體',
        strikethrough: '刪除線',
        subscript: '下標',
        superscript: '上標',
        size: '字號',
      },
      image: {
        image: '圖片',
        insert: '插入圖片',
        resizeFull: '縮放至100%',
        resizeHalf: '縮放至 50%',
        resizeQuarter: '縮放至 25%',
        floatLeft: '靠左浮動',
        floatRight: '靠右浮動',
        floatNone: '取消浮動',
        shapeRounded: '形狀: 圓角',
        shapeCircle: '形狀: 圓',
        shapeThumbnail: '形狀: 縮略圖',
        shapeNone: '形狀: 無',
        dragImageHere: '將圖片拖曳至此處',
        dropImage: 'Drop image or Text',
        selectFromFiles: '從本機上傳',
        maximumFileSize: '文件大小最大值',
        maximumFileSizeError: '文件大小超出最大值。',
        url: '圖片網址',
        remove: '移除圖片',
        original: 'Original',
      },
      video: {
        video: '影片',
        videoLink: '影片連結',
        insert: '插入影片',
        url: '影片網址',
        providers: '(優酷, Instagram, DailyMotion, Youtube等)',
      },
      link: {
        link: '連結',
        insert: '插入連結',
        unlink: '取消連結',
        edit: '編輯連結',
        textToDisplay: '顯示文字',
        url: '連結網址',
        openInNewWindow: '在新視窗開啟',
      },
      table: {
        table: '表格',
        addRowAbove: '上方插入列',
        addRowBelow: '下方插入列',
        addColLeft: '左方插入欄',
        addColRight: '右方插入欄',
        delRow: '刪除列',
        delCol: '刪除欄',
        delTable: '刪除表格',
      },
      hr: {
        insert: '水平線',
      },
      style: {
        style: '樣式',
        p: '一般',
        blockquote: '引用區塊',
        pre: '程式碼區塊',
        h1: '標題 1',
        h2: '標題 2',
        h3: '標題 3',
        h4: '標題 4',
        h5: '標題 5',
        h6: '標題 6',
      },
      lists: {
        unordered: '項目清單',
        ordered: '編號清單',
      },
      options: {
        help: '幫助',
        fullscreen: '全螢幕',
        codeview: '原始碼',
      },
      paragraph: {
        paragraph: '段落',
        outdent: '取消縮排',
        indent: '增加縮排',
        left: '靠右對齊',
        center: '靠中對齊',
        right: '靠右對齊',
        justify: '左右對齊',
      },
      color: {
        recent: '字型顏色',
        more: '更多',
        background: '背景',
        foreground: '字體',
        transparent: '透明',
        setTransparent: '透明',
        reset: '重設',
        resetToDefault: '預設',
      },
      shortcut: {
        shortcuts: '快捷鍵',
        close: '關閉',
        textFormatting: '文字格式',
        action: '動作',
        paragraphFormatting: '段落格式',
        documentStyle: '文件格式',
        extraKeys: '額外按鍵',
      },
      help: {
        'insertParagraph': 'Insert Paragraph',
        'undo': 'Undoes the last command',
        'redo': 'Redoes the last command',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Set a bold style',
        'italic': 'Set a italic style',
        'underline': 'Set a underline style',
        'strikethrough': 'Set a strikethrough style',
        'removeFormat': 'Clean a style',
        'justifyLeft': 'Set left align',
        'justifyCenter': 'Set center align',
        'justifyRight': 'Set right align',
        'justifyFull': 'Set full align',
        'insertUnorderedList': 'Toggle unordered list',
        'insertOrderedList': 'Toggle ordered list',
        'outdent': 'Outdent on current paragraph',
        'indent': 'Indent on current paragraph',
        'formatPara': 'Change current block\'s format as a paragraph(P tag)',
        'formatH1': 'Change current block\'s format as H1',
        'formatH2': 'Change current block\'s format as H2',
        'formatH3': 'Change current block\'s format as H3',
        'formatH4': 'Change current block\'s format as H4',
        'formatH5': 'Change current block\'s format as H5',
        'formatH6': 'Change current block\'s format as H6',
        'insertHorizontalRule': 'Insert horizontal rule',
        'linkDialog.show': 'Show Link Dialog',
      },
      history: {
        undo: '復原',
        redo: '取消復原',
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Select Special characters',
      },
    },
  });
})(jQuery);
